import { Icon } from '@cbrebuild/blocks';

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import userEventService from '../../services/user-event-service';
import LabeledItem from '../../nucleus/labeled-item/labeled-item';

const SPOCSearchListItem = ({ spoc, index }) => {
  const {
    account,
    business,
    city,
    country,
    geo,
    region,
    services,
    state_province,
    spoc_name,
    spoc_email,
    team,
  } = spoc;

  const analyticProperties = {
    actionPrefix: 'spoc_search_',
    categoryPrefix: 'SPOC ',
  };

  const geographyString = `${geo && geo}
                           ${region && ` · ${region} `}
                           ${country && ` · ${country} `}
                           ${state_province && ` · ${state_province}`}
                           ${city && ` · ${city}`}`;

  const serviceString = `${business && `${business} `}
                         ${services && ` · ${services} `}`;

  const trackAccountClick = () => {
    const eventCategory = `${analyticProperties.categoryPrefix}Account Click`;
    const eventAction = `${analyticProperties.actionPrefix}account_clicked`;
    userEventService.trackEvent({
      eventCategory,
      eventAction,
      eventLabel: index,
      eventData: {
        position_x: index,
      },
    });
  };

  // const transformAccountStr = (str) => {
  //   const regex = /\b(\w*corp|corporation|co|inc|incorporated|ltd\w*)\b|[,./]/ig;
  //   const transformed = str.replace(regex, '');
  //   return transformed;
  // };

  // Check with someone, fires twice after clicking email
  const trackEmailClicked = () => {
    const eventAction = `${analyticProperties.actionPrefix}email_clicked`;
    const eventCategory = `${analyticProperties.categoryPrefix}Email Click`;
    userEventService.trackEvent({
      eventCategory,
      eventAction,
      eventLabel: index,
      eventData: {
        position_x: index,
      },
    });
  };

  return (
    <div className="spoc-search-list-item">
      <li>
        <div className="col-1">
          <Link
            className="primary-link"
            onClick={trackAccountClick}
            to={{
              pathname: '/vouchers',
              search: `?company=${account}`,
            }}
          >
            {account}
          </Link>
          <div className="location-info">
            <div>
              <LabeledItem label="Geography" item={geographyString || '--'} />
              <LabeledItem label="Services" item={serviceString || '--'} />
            </div>
          </div>
        </div>
        <div className="col-2">
          <label>SPOC</label>
          <div className="email" onClick={trackEmailClicked}>
            <a href={`mailto:${spoc_email}`}>
              {spoc_name}
              <Icon iconName="envelope" />
            </a>
          </div>
        </div>
        <div className="col-3">
          <label>TEAM</label>
          <span>{team || '--'}</span>
        </div>
      </li>
    </div>
  );
};

SPOCSearchListItem.propTypes = {
  spoc: PropTypes.shape({
    account: PropTypes.string,
    business: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    geo: PropTypes.string,
    region: PropTypes.string,
    services: PropTypes.string,
    state_province: PropTypes.string,
    spoc_name: PropTypes.string,
    spoc_email: PropTypes.string,
    team: PropTypes.string,
  }).isRequired,
  index: PropTypes.string.isRequired,
};

export default SPOCSearchListItem;
