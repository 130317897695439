const isEmptyField = (path, RefName) => ({
  path,
  RefName,
  pattern: /\S+/,
  message: 'This is a required field',
  emptyCheck: true,
});

const isEmailField = (path, RefName) => ({
  path,
  RefName,
  // eslint-disable-next-line no-useless-escape
  pattern: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
  message: 'Invalid email',
});

// const isDateField = (path, RefName) => ({
//   path,
//   RefName,
//   // eslint-disable-next-line no-useless-escape
//   pattern: /^(19|20)\d{2}\-(0?[1-9]|1[0-2])\-(0?[1-9]|[12][0-9]|3[01])$/,
//   message: 'Invalid Date',
// });

const isDecimalField = (path, RefName) => ({
  path,
  RefName,
  // eslint-disable-next-line no-useless-escape
  pattern: /^\d+(\.\d{1,2})?$/,
  message: 'Must be a numeric value up to 2 decimal places',
});

const isZipCodeField = (path, RefName) => ({
  path,
  RefName,
  // eslint-disable-next-line no-useless-escape
  pattern: /^\d{5}$/,
  message: 'Must be a numeric value of 5 digits',
});

export { isEmptyField, isEmailField, isDecimalField, isZipCodeField };
