import { isEmailField, isDecimalField, isZipCodeField } from './validation';

const validationSchema = [
  isEmailField('tenantPOCEmail.value', 'tenantPOCEmail'),
  isEmailField('landlordPOCEmail.value', 'landlordPOCEmail'),
  isDecimalField('parcelSize.value', 'parcelSize'),
  isDecimalField('baseRent.value', 'baseRent'),
  isDecimalField(
    'tenantImprovementAllowance.value',
    'tenantImprovementAllowance',
  ),
  isDecimalField('parkingRatio.value', 'parkingRatio'),
  isDecimalField('squareFeet.value', 'squareFeet'),
  isZipCodeField('zip.value', 'zip'),
];

export default validationSchema;
