/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/extensions
import { FaRegWindowMinimize } from 'react-icons/fa';
import { Button, IconButton } from '@cbrebuild/blocks';
import './lease-abstraction.scss';
import dealsService from '../../services/deals-service';
import vouchersService from '../../services/voucher/vouchers-service';
import * as compDataService from '../../services/voucher/comp-data-service';
import companiesService from '../../services/companies-service';
import contactsService from '../../services/contacts-service';
import LeaseDetails from './lease-details/lease-details';
import authService from '../../services/auth-service';
import LeaseComp from './lease-comp/lease-comp';
import countries from '../../data/countries.json';
import states from '../../data/states.json';
import validatePayload from '../../utils/validation-utils';
import validationSchema from './validation-schema';
import useFileAbstractionFields from '../../hooks/useFileAbstractionFields';
import userEventService from '../../services/user-event-service';
import { fetchAttachmentsViewLink } from '../../services/attachments-service';

import { toast } from '../../redux/actions/toasts-actions';

/* global CONFIG */
const { apiUrl } = CONFIG;

const FileModal = (props) => {
  const {
    hideDataModal,
    closeDataModal,
    results,
    deal,
    file,
    analyticProperties: { actionPrefix, categoryPrefix },
  } = props;
  const getFilteredStates = (countryName = 'United States') => {
    const found = countries.find(c => c.display_name === countryName);
    return states.filter(s => s.country_id === found?.country_id);
  };
  const fileId = file.id;
  const dispatch = useDispatch();

  const tenantImprovementDetailOptions = [
    { display_name: 'Allowance', value: 'Allowance' },
    { display_name: 'As Is', value: 'As Is' },
    { display_name: 'Spec Suite', value: 'Spec Suite' },
    { display_name: 'Turn Key', value: 'Turn Key' },
  ];

  const responseData = useRef(null);
  const [showMoreInstructions, setShowMoreInstructions] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('lease-details');
  const [voucherId, setVoucherId] = useState(null);
  const [commercialPropertyId, setCommercialPropertyId] = useState(null);
  const [counterPartyCompanyId, setCounterPartyCompanyId] = useState(null);
  const [tenantContactId, setTenantContactId] = useState(null);
  const [landlordContactId, setLandlordContactId] = useState(null);
  const [clientCompanyId, setClientCompanyId] = useState(null);
  const [repRole, setRepRole] = useState(null);
  const [internalCompId, setInternalCompId] = useState(null);
  const [propertyOptions, setPropertyOptions] = useState([
    { display_name: 'Select', value: null },
  ]);
  const [fieldErrorMap, setFieldErrorMap] = useState({});
  // State for all the modal fields
  const [modalFieldState, dispatchModalFieldState] = useFileAbstractionFields();
  const [responseState, dispatchResponseState] = useFileAbstractionFields();
  const [viewLink, setViewLink] = useState('');
  const [dealName, setDealName] = useState('');

  const updateAttachmentsExtract = (id, params) => () => {
    const url = `${apiUrl}/mbb/extractupdate/${id}`;
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    };
    return fetch(url, options)
      .then((response) => {
        if (response.status !== 200) {
          dispatch(toast('Error updating data. Please try again.'));
        } else {
          dispatch(toast('Data imported successfully.'));
        }
        return response;
      })
      .catch((error) => {
        dispatch(toast('Error updating data. Please try again.'));
        console.error('Error:', error);
      });
  };

  useEffect(() => {
    const handleFileViewer = async () => {
      try {
        const link = await fetchAttachmentsViewLink(fileId);
        setViewLink(link);
      } catch (error) {
        console.error('Error fetching view link:', error);
      }
    };
    handleFileViewer();
  }, [fileId]);

  const baseUrl = window.location.origin;
  const pdfViewerUrl = `${baseUrl}/react-app/libraries/pdf-js/web/viewer.html?file=${encodeURIComponent(viewLink)}`;

  useEffect(() => {
    const fetchContacts = async (params = {
      ordering: '-is_primary',
      page: 1,
      page_size: 25,
      deal,
    }) => {
      try {
        const response = await contactsService.getContacts(params);
        if (response && response.results) {
          const tenantContact = response.results.find(contact => contact.contact_type === 'tenant');
          const landlordContact = response.results.find(contact => contact.contact_type === 'landlord');

          if (tenantContact) {
            setTenantContactId(tenantContact.id);
          } else {
            console.error('Tenant contact not found');
          }

          if (landlordContact) {
            setLandlordContactId(landlordContact.id);
          } else {
            console.error('Landlord contact not found');
          }
        } else {
          console.error('No data found in response');
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };
    const url = `${apiUrl}/mbb/deals/${deal}`;
    fetch(url, {
      headers: {
        Authorization: `JWT ${authService.getToken()}`,
      },
    })
      .then(response => response.json())
      .then((data) => {
        setDealName(data.name);
        responseData.current = data;
        setVoucherId(data.voucher);
        setInternalCompId(data.internalcomp);
        setCounterPartyCompanyId(data.counter_party.id);
        setClientCompanyId(data.client.id);
        setCommercialPropertyId(data.voucher_commercial_properties[0].id);
        fetchContacts();
        setRepRole(data.rep_role);
      })
      .catch(error => console.error('Error fetching data:', error));
    vouchersService.getPropertyOptions().then((res) => {
      if (
        res.actions.POST.voucher_property_type_category.choices.length > 0 &&
        propertyOptions.length === 1
      ) {
        setPropertyOptions(propertyOptions.concat(res.actions.POST.voucher_property_type_category.choices));
      }
    });
  }, [deal, propertyOptions]); // The empty array ensures this effect runs only once after the initial render

  // Field change handlers for LD Lease Information

  const modalFieldChangeHandler = useCallback(
    (field, value, type = 'single') => {
      dispatchModalFieldState({
        field,
        value,
        type,
      });
    },
    [dispatchModalFieldState],
  );

  const setResponseState = useCallback(
    (field, value, type = 'single') => {
      dispatchResponseState({
        field,
        value,
        type,
      });
    },
    [dispatchResponseState],
  );

  const trackLeaseAbstractionChanges = (stateVariable) => {
    if (
      modalFieldState[`${stateVariable}`].value !==
      responseState[`${stateVariable}`].value
    ) {
      userEventService.trackEvent(
        {
          eventAction: 'lae_field',
          eventCategory: 'Field Value Changed',
          eventValue: {
            extractedValue: responseState[`${stateVariable}`].value,
            currentValue: modalFieldState[`${stateVariable}`].value,
            fileId,
            fieldName: modalFieldState[`${stateVariable}`].trackingID,
          },
        },
        {
          actionPrefix,
          categoryPrefix,
        },
      );
    }
  };

  const trackImportData = () => {
    userEventService.trackEvent(
      {
        eventAction: 'import_data_clicked',
        eventCategory: 'File Action',
        eventValue: {
          fileId,
          tab: activeTab,
        },
      },
      {
        actionPrefix,
        categoryPrefix,
      },
    );
  };

  useEffect(() => {
    console.log('FX RESULTS', results);
    const initializeField = (
      fieldName,
      valuePath,
      basePath,
      transformFn = val => val,
    ) => {
      const fieldInfo =
        valuePath
          .split('.')
          .reduce(
            (acc, part) => acc && acc[part],
            results[0]?.data_extract?.[basePath],
          ) || '';
      modalFieldChangeHandler(fieldName, {
        value: transformFn(fieldInfo.value),
        toolTip: {
          section: fieldInfo.section,
          paragraph: fieldInfo.paragraph,
          pageIndex: fieldInfo.page_index
            ? `${fieldInfo.page_index}*`
            : fieldInfo.page_index,
        },
      });
      setResponseState(fieldName, {
        value: transformFn(fieldInfo.value),
        toolTip: {
          section: fieldInfo.section,
          paragraph: fieldInfo.paragraph,
          pageIndex: fieldInfo.page_index,
        },
      });
    };
    // eslint-disable-next-line react/prop-types
    if (results && results.length > 0) {
      // Lease Info
      initializeField('propertyName', 'property_name', 'lease_info');
      initializeField(
        'multipleFieldNames',
        'country',
        'lease_info',
        initialCountry => ({
          country: initialCountry,
          stateName: '',
          filteredStatesForProperty: getFilteredStates(initialCountry),
        }),
      );
      initializeField('propertyType', 'property_type_subtype', 'lease_info');
      initializeField(
        'leaseStartDate',
        'lease_start_date',
        'lease_info',
        // convertDateFormat,
      );
      initializeField(
        'leaseEndDate',
        'lease_expiration_date',
        'lease_info',
        // convertDateFormat,
      );
      initializeField(
        'leaseExecutionDate',
        'lease_execution_date',
        'lease_info',
        // convertDateFormat,
      );
      initializeField(
        'leaseOccupancyDate',
        'lease_occupancy_date',
        'lease_info',
        // convertDateFormat,
      );
      initializeField('address', 'address', 'lease_info');
      initializeField('city', 'city', 'lease_info');
      initializeField('stateName', 'state_province', 'lease_info');
      initializeField('zip', 'zip', 'lease_info');
      initializeField('isASublease', 'is_sublease', 'lease_info');
      initializeField('isListedByCBRE', 'is_listed_by_cbre', 'lease_info');
      initializeField('isWholeBuilding', 'is_whole_building', 'lease_info');
      initializeField('parcelSize', 'parcel_size', 'lease_info');
      initializeField('suite', 'suite', 'lease_info');
      initializeField('floorChange', 'floor_number', 'lease_info');
      initializeField('squareFeet', 'square_footage', 'lease_info');

      // tenant_buyer_info fields
      initializeField('tenantCompanyName', 'org_name', 'tenant_buyer_info');
      initializeField(
        'multipleFieldNames',
        'country',
        'landlord_seller_info',
        initialCountry => ({
          tenantCountryName: initialCountry,
          tenantStateName: '',
          filteredStatesForTenant: getFilteredStates(initialCountry),
        }),
      );
      initializeField('tenantAddressLine1', 'address', 'tenant_buyer_info');
      initializeField('tenantAddressLine2', 'address2', 'tenant_buyer_info');
      initializeField('tenantCity', 'city', 'tenant_buyer_info');
      initializeField('tenantStateName', 'state_province', 'tenant_buyer_info');
      initializeField('tenantZip', 'zip', 'tenant_buyer_info');
      initializeField('tenantPOCPhone', 'contact_phone', 'tenant_buyer_info');
      initializeField('tenantPOCEmail', 'contact_email', 'tenant_buyer_info');
      initializeField(
        'tenantPOCFirstName',
        'contact_first_name',
        'tenant_buyer_info',
      );
      initializeField(
        'tenantPOCLastName',
        'contact_last_name',
        'tenant_buyer_info',
      );
      initializeField(
        'tenantPOCJobTitle',
        'contact_title',
        'tenant_buyer_info',
      );

      // landlord_seller_info fields
      initializeField(
        'landlordCompanyName',
        'org_name',
        'landlord_seller_info',
      );
      initializeField(
        'multipleFieldNames',
        'country',
        'landlord_seller_info',
        initialCountry => ({
          landlordCountryName: initialCountry,
          landlordStateName: '',
          filteredStatesForLandlord: getFilteredStates(initialCountry),
        }),
      );
      initializeField(
        'landlordAddressLine1',
        'address',
        'landlord_seller_info',
      );
      initializeField(
        'landlordAddressLine2',
        'address2',
        'landlord_seller_info',
      );
      initializeField('landlordCity', 'city', 'landlord_seller_info');
      initializeField(
        'landlordStateName',
        'state_province',
        'landlord_seller_info',
      );
      initializeField('landlordZip', 'zip', 'landlord_seller_info');
      initializeField(
        'landlordPOCPhone',
        'contact_phone',
        'landlord_seller_info',
      );
      initializeField(
        'landlordPOCEmail',
        'contact_email',
        'landlord_seller_info',
      );
      initializeField(
        'landlordPOCFirstName',
        'contact_first_name',
        'landlord_seller_info',
      );
      initializeField(
        'landlordPOCLastName',
        'contact_last_name',
        'landlord_seller_info',
      );
      initializeField(
        'landlordPOCJobTitle',
        'contact_title',
        'landlord_seller_info',
      );

      // comp_info fields
      initializeField(
        'tenantRepresentCompany',
        'tenant_represented_by',
        'comp_info',
      );
      initializeField(
        'tenantRepresentAgent',
        'tenant_represented_by_agent',
        'comp_info',
      );
      initializeField(
        'landlordRepresentCompany',
        'landlord_represented_by',
        'comp_info',
      );
      initializeField(
        'landlordRepresentAgent',
        'landlord_represented_by_agent',
        'comp_info',
      );
      initializeField('baseRent', 'base_rent', 'comp_info');
      initializeField(
        'tenantImprovementAllowance',
        'tenant_improvement_allowance_amount_per_sqft',
        'comp_info',
      );
      initializeField(
        'tenantImprovementDetails',
        'tenant_improvements_detail',
        'comp_info',
      );
      initializeField('parkingRatio', 'parking_ratio', 'comp_info');
    }
  }, [modalFieldChangeHandler, setResponseState, results]);

  const validateLeaseAbstractionFields = () => {
    const { isPayloadValid, errorMap } = validatePayload(
      validationSchema,
      modalFieldState,
    );
    setFieldErrorMap(errorMap);

    return {
      isPayloadValid,
      errorMessages: Object.entries(errorMap)
        .map(([field, message]) => `${field}: ${message}`)
        .join(', '),
    };
  };

  // Helper function to check if a value is not undefined
  function isValueUpdated(value) {
    return value !== undefined;
  }

  // eslint-disable-next-line consistent-return
  const handleUpdateAll = () => {
    // Validate the fields
    const { isPayloadValid, errorMessages } = validateLeaseAbstractionFields();

    // Initialize patch objects
    const dealUpdatePatch = {};
    const voucherUpdatePatch = {};
    const commercialPropertyUpdatePatch = {};
    const internalCompUpdatePatch = {};
    const counterPartyCompanyUpdatePatch = {};
    const clientCompanyUpdatePatch = {};
    const tenantContactUpdatePatch = {};
    const landlordContactUpdatePatch = {};

    // gather fields for patch to attachments extract api
    const updatedFields = {
      tenant_buyer_info: {},
      landlord_seller_info: {},
      lease_info: {},
      comp_info: {},
    };

    const propertyTypeValue = modalFieldState.propertyType.value;
    const isPropertyTypeValid = propertyOptions.some(option => option.value === propertyTypeValue);

    const tenantImprovementDetailsValue =
      modalFieldState.tenantImprovementDetails.value;
    const isTenantImprovementDetailsValid = tenantImprovementDetailOptions.some(option => option.value === tenantImprovementDetailsValue);

    if (
      isPayloadValid &&
      modalFieldState.isLeaseStartDateValid &&
      modalFieldState.isLeaseEndDateValid &&
      modalFieldState.isLeaseExecutionDateValid &&
      modalFieldState.isLeaseOccupancyDateValid &&
      modalFieldState.tenantPOCPhoneValid &&
      modalFieldState.landlordPOCPhoneValid &&
      isPropertyTypeValid &&
      isTenantImprovementDetailsValid
    ) {
      if (activeTab === 'lease-details') {
        // Conditionally add properties to dealUpdatePatch
        let leaseStartDateValue = modalFieldState.leaseStartDate.value;
        leaseStartDateValue =
          leaseStartDateValue === null ? '' : leaseStartDateValue;
        const isLeaseStartDateUpdated =
          leaseStartDateValue.length > 0 && isValueUpdated(leaseStartDateValue);

        dealUpdatePatch.date_lease_from = isLeaseStartDateUpdated
          ? leaseStartDateValue
          : null;
        updatedFields.lease_info.lease_start_date = isLeaseStartDateUpdated
          ? leaseStartDateValue
          : null;
        trackLeaseAbstractionChanges('leaseStartDate');

        let leaseExecutionDateValue = modalFieldState.leaseExecutionDate.value;
        leaseExecutionDateValue =
          leaseExecutionDateValue === null ? '' : leaseExecutionDateValue;
        const isLeaseExecutionDateUpdated =
          leaseExecutionDateValue.length > 0 &&
          isValueUpdated(leaseExecutionDateValue);

        dealUpdatePatch.date_lease_execution = isLeaseExecutionDateUpdated
          ? leaseExecutionDateValue
          : null;
        updatedFields.lease_info.lease_execution_date =
          isLeaseExecutionDateUpdated ? leaseExecutionDateValue : null;
        trackLeaseAbstractionChanges('leaseExecutionDate');

        let leaseOccupancyDateValue = modalFieldState.leaseOccupancyDate.value;
        leaseOccupancyDateValue =
          leaseOccupancyDateValue === null ? '' : leaseOccupancyDateValue;
        const isLeaseOccupancyDateUpdated =
          leaseOccupancyDateValue.length > 0 &&
          isValueUpdated(leaseOccupancyDateValue);

        dealUpdatePatch.date_lease_occupancy = isLeaseOccupancyDateUpdated
          ? leaseOccupancyDateValue
          : null;
        updatedFields.lease_info.lease_occupancy_date =
          isLeaseOccupancyDateUpdated ? leaseOccupancyDateValue : null;
        trackLeaseAbstractionChanges('leaseOccupancyDate');

        if (isValueUpdated(modalFieldState.isASublease.value)) {
          dealUpdatePatch.is_sublease = modalFieldState.isASublease.value;
          updatedFields.lease_info.is_sublease =
            modalFieldState.isASublease.value;
          trackLeaseAbstractionChanges('isASublease');
        }

        // Update the deal with a single API call if dealUpdatePatch is not empty
        if (Object.keys(dealUpdatePatch).length > 0) {
          dealsService.updateDeal(deal, dealUpdatePatch);
        }

        // Conditionally add properties to voucherUpdatePatch
        let leaseEndDateValue = modalFieldState.leaseEndDate.value;
        leaseEndDateValue = leaseEndDateValue === null ? '' : leaseEndDateValue;
        const isLeaseEndDateUpdated =
          leaseEndDateValue.length > 0 && isValueUpdated(leaseEndDateValue);

        voucherUpdatePatch.lease_expiration_date = isLeaseEndDateUpdated
          ? leaseEndDateValue
          : null;
        updatedFields.lease_info.lease_expiration_date = isLeaseEndDateUpdated
          ? leaseEndDateValue
          : null;
        trackLeaseAbstractionChanges('leaseEndDate');

        // Update the voucher if voucherUpdatePatch is not empty
        if (Object.keys(voucherUpdatePatch).length > 0) {
          vouchersService.updateVoucher(voucherId, voucherUpdatePatch);
        }

        // Conditionally add properties to commercialPropertyUpdatePatch
        if (isValueUpdated(modalFieldState.propertyName.value)) {
          commercialPropertyUpdatePatch.name =
            modalFieldState.propertyName.value;
          updatedFields.lease_info.property_name =
            modalFieldState.propertyName.value;
          trackLeaseAbstractionChanges('propertyName');
        }
        if (isValueUpdated(modalFieldState.address.value)) {
          commercialPropertyUpdatePatch.address = modalFieldState.address.value;
          updatedFields.lease_info.address = modalFieldState.address.value;
          trackLeaseAbstractionChanges('address');
        }
        if (isValueUpdated(modalFieldState.country.value)) {
          commercialPropertyUpdatePatch.country = modalFieldState.country.value;
          updatedFields.lease_info.country = modalFieldState.country.value;
          trackLeaseAbstractionChanges('country');
        }
        if (isValueUpdated(modalFieldState.city.value)) {
          commercialPropertyUpdatePatch.city = modalFieldState.city.value;
          updatedFields.lease_info.city = modalFieldState.city.value;
          trackLeaseAbstractionChanges('city');
        }
        if (isValueUpdated(modalFieldState.stateName.value)) {
          commercialPropertyUpdatePatch.state_abbreviation =
            modalFieldState.stateName.value;
          updatedFields.lease_info.state_province =
            modalFieldState.stateName.value;
          trackLeaseAbstractionChanges('stateName');
        }
        if (isValueUpdated(modalFieldState.zip.value)) {
          commercialPropertyUpdatePatch.postal_code = modalFieldState.zip.value;
          updatedFields.lease_info.zip = modalFieldState.zip.value;
          trackLeaseAbstractionChanges('zip');
        }
        if (isValueUpdated(modalFieldState.propertyType.value)) {
          commercialPropertyUpdatePatch.voucher_property_type_category =
            modalFieldState.propertyType.value;
          updatedFields.lease_info.property_type_subtype =
            modalFieldState.propertyType.value;
          trackLeaseAbstractionChanges('propertyType');
        }
        if (isValueUpdated(modalFieldState.parcelSize.value)) {
          commercialPropertyUpdatePatch.parcel_size =
            modalFieldState.parcelSize.value;
          updatedFields.lease_info.parcel_size =
            modalFieldState.parcelSize.value;
          trackLeaseAbstractionChanges('parcelSize');
        }
        if (isValueUpdated(modalFieldState.isListedByCBRE.value)) {
          commercialPropertyUpdatePatch.is_listed =
            modalFieldState.isListedByCBRE.value;
          updatedFields.lease_info.is_listed_by_cbre =
            modalFieldState.isListedByCBRE.value;
          trackLeaseAbstractionChanges('isListedByCBRE');
        }
        if (modalFieldState.isWholeBuilding.value.toLowerCase() === 'true') {
          if (isValueUpdated(modalFieldState.isWholeBuilding.value)) {
            commercialPropertyUpdatePatch.whole_building =
              modalFieldState.isWholeBuilding.value.toLowerCase();
            updatedFields.lease_info.is_whole_building =
              modalFieldState.isWholeBuilding.value.toLowerCase();
            trackLeaseAbstractionChanges('isWholeBuilding');
          }
          if (isValueUpdated(modalFieldState.squareFeet.value)) {
            commercialPropertyUpdatePatch.building_total_square_feet =
              modalFieldState.squareFeet.value;
            updatedFields.lease_info.square_footage =
              modalFieldState.squareFeet.value;
            trackLeaseAbstractionChanges('squareFeet');
          }
          updatedFields.lease_info.suite = '';
          updatedFields.lease_info.floor_number = '';
        }
        if (modalFieldState.isWholeBuilding.value.toLowerCase() === 'false') {
          const space = {
            lease_expiration_date: null,
            lease_start_date: null,
          };
          if (isValueUpdated(modalFieldState.isWholeBuilding.value)) {
            commercialPropertyUpdatePatch.whole_building =
              modalFieldState.isWholeBuilding.value.toLowerCase();
            updatedFields.lease_info.is_whole_building =
              modalFieldState.isWholeBuilding.value.toLowerCase();
            trackLeaseAbstractionChanges('isWholeBuilding');
          }
          if (isValueUpdated(modalFieldState.suite.value)) {
            space.address_line2 = modalFieldState.suite.value;
            updatedFields.lease_info.suite = modalFieldState.suite.value;
            trackLeaseAbstractionChanges('suite');
          }
          if (isValueUpdated(modalFieldState.floorChange.value)) {
            space.floor = modalFieldState.floorChange.value;
            updatedFields.lease_info.floor_number =
              modalFieldState.floorChange.value;
            trackLeaseAbstractionChanges('floorChange');
          }
          if (isValueUpdated(modalFieldState.squareFeet.value)) {
            space.space_total_square_feet = parseInt(
              modalFieldState.squareFeet.value,
              10,
            );
            updatedFields.lease_info.square_footage =
              modalFieldState.squareFeet.value;
            trackLeaseAbstractionChanges('squareFeet');
          }

          if (Object.keys(space).length > 2) {
            commercialPropertyUpdatePatch.spaces = [space];
          }
        }

        // Update the commercial property if commercialPropertyUpdatePatch is not empty
        if (Object.keys(commercialPropertyUpdatePatch).length > 0) {
          vouchersService.updateCommercialProperty(
            commercialPropertyId,
            commercialPropertyUpdatePatch,
          );
        }

        // Conditionally add properties for landlordCompany/Counter_party to companyUpdatePatch
        if (isValueUpdated(modalFieldState.landlordCompanyName.value)) {
          counterPartyCompanyUpdatePatch.name =
            modalFieldState.landlordCompanyName.value;
          updatedFields.landlord_seller_info.org_name =
            modalFieldState.landlordCompanyName.value;
          trackLeaseAbstractionChanges('landlordCompanyName');
        }
        if (isValueUpdated(modalFieldState.landlordCountryName.value)) {
          counterPartyCompanyUpdatePatch.country =
            modalFieldState.landlordCountryName.value;
          updatedFields.landlord_seller_info.country =
            modalFieldState.landlordCountryName.value;
          trackLeaseAbstractionChanges('landlordCountryName');
        }
        if (isValueUpdated(modalFieldState.landlordCity.value)) {
          counterPartyCompanyUpdatePatch.city =
            modalFieldState.landlordCity.value;
          updatedFields.landlord_seller_info.city =
            modalFieldState.landlordCity.value;
          trackLeaseAbstractionChanges('landlordCity');
        }
        if (isValueUpdated(modalFieldState.landlordAddressLine1.value)) {
          counterPartyCompanyUpdatePatch.address =
            modalFieldState.landlordAddressLine1.value;
          updatedFields.landlord_seller_info.address =
            modalFieldState.landlordAddressLine1.value;
          trackLeaseAbstractionChanges('landlordAddressLine1');
        }
        if (isValueUpdated(modalFieldState.landlordAddressLine2.value)) {
          counterPartyCompanyUpdatePatch.address2 =
            modalFieldState.landlordAddressLine2.value;
          updatedFields.landlord_seller_info.address2 =
            modalFieldState.landlordAddressLine2.value; // do we have 2 addresses
          trackLeaseAbstractionChanges('landlordAddressLine2');
        }
        if (isValueUpdated(modalFieldState.landlordStateName.value)) {
          counterPartyCompanyUpdatePatch.territory =
            modalFieldState.landlordStateName.value;
          updatedFields.landlord_seller_info.state_province =
            modalFieldState.landlordStateName.value;
          trackLeaseAbstractionChanges('landlordStateName');
        }
        if (isValueUpdated(modalFieldState.landlordZip.value)) {
          counterPartyCompanyUpdatePatch.postal =
            modalFieldState.landlordZip.value;
          updatedFields.landlord_seller_info.zip =
            modalFieldState.landlordZip.value;
          trackLeaseAbstractionChanges('landlordZip');
        }

        if (Object.keys(counterPartyCompanyUpdatePatch).length > 0) {
          if (repRole === 'tenant') {
            companiesService.patchCompany(
              counterPartyCompanyId,
              counterPartyCompanyUpdatePatch,
            );
          } else {
            companiesService.patchCompany(
              clientCompanyId,
              counterPartyCompanyUpdatePatch,
            );
          }
        }

        if (isValueUpdated(modalFieldState.landlordPOCPhone.value)) {
          landlordContactUpdatePatch.phone_numbers = [
            {
              type: 'work',
              contact_type: 'landlord',
              number: modalFieldState.landlordPOCPhone.value,
              name: '',
              default: true,
              isValid: true,
            },
          ];
          updatedFields.landlord_seller_info.contact_phone =
            modalFieldState.landlordPOCPhone.value;
          trackLeaseAbstractionChanges('landlordPOCPhone');
        }

        if (isValueUpdated(modalFieldState.landlordPOCEmail.value)) {
          landlordContactUpdatePatch.email_addresses = [
            {
              type: 'work',
              contact_type: 'landlord',
              address: modalFieldState.landlordPOCEmail.value,
              name: '',
              default: true,
              isValid: true,
            },
          ];
          updatedFields.landlord_seller_info.contact_email =
            modalFieldState.landlordPOCEmail.value;
          trackLeaseAbstractionChanges('landlordPOCEmail');
        }

        if (isValueUpdated(modalFieldState.landlordPOCFirstName.value)) {
          landlordContactUpdatePatch.given_name =
            modalFieldState.landlordPOCFirstName.value;
          updatedFields.landlord_seller_info.contact_first_name =
            modalFieldState.landlordPOCFirstName.value;
          trackLeaseAbstractionChanges('landlordPOCFirstName');
        }

        if (isValueUpdated(modalFieldState.landlordPOCLastName.value)) {
          landlordContactUpdatePatch.surname =
            modalFieldState.landlordPOCLastName.value;
          updatedFields.landlord_seller_info.contact_last_name =
            modalFieldState.landlordPOCLastName.value;
          trackLeaseAbstractionChanges('landlordPOCLastName');
        }

        if (isValueUpdated(modalFieldState.landlordPOCJobTitle.value)) {
          landlordContactUpdatePatch.job_title =
            modalFieldState.landlordPOCJobTitle.value;
          updatedFields.landlord_seller_info.contact_title =
            modalFieldState.landlordPOCJobTitle.value;
          trackLeaseAbstractionChanges('landlordPOCJobTitle');
        }

        if (Object.keys(landlordContactUpdatePatch).length > 0) {
          contactsService.patchContact(
            landlordContactId,
            landlordContactUpdatePatch,
          );
        }

        // Conditionally add properties for TenantComapny/Client to companyUpdatePatch
        if (isValueUpdated(modalFieldState.tenantCompanyName.value)) {
          clientCompanyUpdatePatch.name =
            modalFieldState.tenantCompanyName.value;
          updatedFields.tenant_buyer_info.org_name =
            modalFieldState.tenantCompanyName.value;
          trackLeaseAbstractionChanges('tenantCompanyName');
        }

        if (isValueUpdated(modalFieldState.tenantCountryName.value)) {
          clientCompanyUpdatePatch.country =
            modalFieldState.tenantCountryName.value;
          updatedFields.tenant_buyer_info.country =
            modalFieldState.tenantCountryName.value;
          trackLeaseAbstractionChanges('tenantCountryName');
        }

        if (isValueUpdated(modalFieldState.tenantCity.value)) {
          clientCompanyUpdatePatch.city = modalFieldState.tenantCity.value;
          updatedFields.tenant_buyer_info.city =
            modalFieldState.tenantCity.value;
          trackLeaseAbstractionChanges('tenantCity');
        }

        if (isValueUpdated(modalFieldState.tenantAddressLine1.value)) {
          clientCompanyUpdatePatch.address =
            modalFieldState.tenantAddressLine1.value;
          updatedFields.tenant_buyer_info.address =
            modalFieldState.tenantAddressLine1.value;
          trackLeaseAbstractionChanges('tenantAddressLine1');
        }

        if (isValueUpdated(modalFieldState.tenantAddressLine2.value)) {
          clientCompanyUpdatePatch.address2 =
            modalFieldState.tenantAddressLine2.value;
          updatedFields.tenant_buyer_info.address2 =
            modalFieldState.tenantAddressLine2.value; // do we have 2 addresses
          trackLeaseAbstractionChanges('tenantAddressLine2');
        }

        if (isValueUpdated(modalFieldState.tenantStateName.value)) {
          clientCompanyUpdatePatch.territory =
            modalFieldState.tenantStateName.value;
          updatedFields.tenant_buyer_info.state_province =
            modalFieldState.tenantStateName.value;
          trackLeaseAbstractionChanges('tenantStateName');
        }

        if (isValueUpdated(modalFieldState.tenantZip.value)) {
          clientCompanyUpdatePatch.postal = modalFieldState.tenantZip.value;
          updatedFields.tenant_buyer_info.zip = modalFieldState.tenantZip.value;
          trackLeaseAbstractionChanges('tenantZip');
        }

        if (Object.keys(clientCompanyUpdatePatch).length > 0) {
          if (repRole === 'tenant') {
            companiesService.patchCompany(
              clientCompanyId,
              clientCompanyUpdatePatch,
            );
          } else {
            companiesService.patchCompany(
              counterPartyCompanyId,
              clientCompanyUpdatePatch,
            );
          }
        }

        if (isValueUpdated(modalFieldState.tenantPOCPhone.value)) {
          tenantContactUpdatePatch.phone_numbers = [
            {
              type: 'work',
              contact_type: 'tenant',
              number: modalFieldState.tenantPOCPhone.value,
              name: '',
              default: true,
              isValid: true,
            },
          ];
          updatedFields.tenant_buyer_info.contact_phone =
            modalFieldState.tenantPOCPhone.value;
          trackLeaseAbstractionChanges('tenantPOCPhone');
        }

        if (isValueUpdated(modalFieldState.tenantPOCEmail.value)) {
          tenantContactUpdatePatch.email_addresses = [
            {
              type: 'work',
              contact_type: 'tenant',
              address: modalFieldState.tenantPOCEmail.value,
              name: '',
              default: true,
              isValid: true,
            },
          ];
          updatedFields.tenant_buyer_info.contact_email =
            modalFieldState.tenantPOCEmail.value;
          trackLeaseAbstractionChanges('tenantPOCEmail');
        }

        if (isValueUpdated(modalFieldState.tenantPOCFirstName.value)) {
          tenantContactUpdatePatch.given_name =
            modalFieldState.tenantPOCFirstName.value;
          updatedFields.tenant_buyer_info.contact_first_name =
            modalFieldState.tenantPOCFirstName.value;
          trackLeaseAbstractionChanges('tenantPOCFirstName');
        }

        if (isValueUpdated(modalFieldState.tenantPOCLastName.value)) {
          tenantContactUpdatePatch.surname =
            modalFieldState.tenantPOCLastName.value;
          updatedFields.tenant_buyer_info.contact_last_name =
            modalFieldState.tenantPOCLastName.value;
          trackLeaseAbstractionChanges('tenantPOCLastName');
        }

        if (isValueUpdated(modalFieldState.tenantPOCJobTitle.value)) {
          tenantContactUpdatePatch.job_title =
            modalFieldState.tenantPOCJobTitle.value;
          updatedFields.tenant_buyer_info.contact_title =
            modalFieldState.tenantPOCJobTitle.value;
          trackLeaseAbstractionChanges('tenantPOCJobTitle');
        }

        if (Object.keys(tenantContactUpdatePatch).length > 0) {
          contactsService.patchContact(
            tenantContactId,
            tenantContactUpdatePatch,
          );
        }
      }

      if (activeTab === 'lease-comp') {
        // Conditionally add properties to internalCompUpdatePatch
        if (isValueUpdated(modalFieldState.tenantRepresentCompany.value)) {
          internalCompUpdatePatch.tenant_represented_by =
            modalFieldState.tenantRepresentCompany.value;
          updatedFields.comp_info.tenant_represented_by =
            modalFieldState.tenantRepresentCompany.value;
          trackLeaseAbstractionChanges('tenantRepresentCompany');
        }
        if (isValueUpdated(modalFieldState.tenantRepresentAgent.value)) {
          internalCompUpdatePatch.tenant_represented_by_agent =
            modalFieldState.tenantRepresentAgent.value;
          updatedFields.comp_info.tenant_represented_by_agent =
            modalFieldState.tenantRepresentAgent.value;
          trackLeaseAbstractionChanges('tenantRepresentAgent');
        }
        if (isValueUpdated(modalFieldState.landlordRepresentCompany.value)) {
          internalCompUpdatePatch.landlord_represented_by =
            modalFieldState.landlordRepresentCompany.value;
          updatedFields.comp_info.landlord_represented_by =
            modalFieldState.landlordRepresentCompany.value;
          trackLeaseAbstractionChanges('landlordRepresentCompany');
        }
        if (isValueUpdated(modalFieldState.landlordRepresentAgent.value)) {
          internalCompUpdatePatch.landlord_represented_by_agent =
            modalFieldState.landlordRepresentAgent.value;
          updatedFields.comp_info.landlord_represented_by_agent =
            modalFieldState.landlordRepresentAgent.value;
          trackLeaseAbstractionChanges('landlordRepresentAgent');
        }
        if (isValueUpdated(modalFieldState.baseRent.value)) {
          internalCompUpdatePatch.base_rent = modalFieldState.baseRent.value;
          updatedFields.comp_info.base_rent = modalFieldState.baseRent.value;
          trackLeaseAbstractionChanges('baseRent');
        }
        if (isValueUpdated(modalFieldState.tenantImprovementAllowance.value)) {
          internalCompUpdatePatch.tenant_improvement_allowance_amount_per_sqft =
            modalFieldState.tenantImprovementAllowance.value;
          updatedFields.comp_info.tenant_improvement_allowance_amount_per_sqft =
            modalFieldState.tenantImprovementAllowance.value;
          trackLeaseAbstractionChanges('tenantImprovementAllowance');
        }
        if (isValueUpdated(modalFieldState.tenantImprovementDetails.value)) {
          internalCompUpdatePatch.tenant_improvements_detail =
            modalFieldState.tenantImprovementDetails.value;
          updatedFields.comp_info.tenant_improvements_detail =
            modalFieldState.tenantImprovementDetails.value;
          trackLeaseAbstractionChanges('tenantImprovementDetails');
        }
        if (isValueUpdated(modalFieldState.parkingRatio.value)) {
          internalCompUpdatePatch.parking_ratio =
            modalFieldState.parkingRatio.value;
          updatedFields.comp_info.parking_ratio =
            modalFieldState.parkingRatio.value;
          trackLeaseAbstractionChanges('parkingRatio');
        }

        // Update the commercial property if commercialPropertyUpdatePatch is having fields other than deal id
        if (Object.keys(internalCompUpdatePatch).length > 0) {
          compDataService.updateCompData(
            internalCompId,
            internalCompUpdatePatch,
          );
        }
      }

      dispatch(updateAttachmentsExtract(fileId, updatedFields));
      return true;
    }
    if (
      errorMessages ||
      !isPropertyTypeValid ||
      !isTenantImprovementDetailsValid ||
      !modalFieldState.isLeaseStartDateValid ||
      !modalFieldState.isLeaseEndDateValid ||
      !modalFieldState.isLeaseExecutionDateValid ||
      !modalFieldState.isLeaseOccupancyDateValid ||
      !modalFieldState.tenantPOCPhoneValid ||
      !modalFieldState.landlordPOCPhoneValid
    ) {
      let fieldError = errorMessages ? `${errorMessages}, ` : '';
      if (!isPropertyTypeValid) {
        fieldError += `Property Type: ${propertyTypeValue}, `;
      }
      if (!isTenantImprovementDetailsValid) {
        fieldError += `Tenant Improvement Details: ${tenantImprovementDetailsValue}, `;
      }
      if (!modalFieldState.isLeaseStartDateValid) {
        fieldError += 'Lease Start Date, ';
      }
      if (!modalFieldState.isLeaseEndDateValid) {
        fieldError += 'Lease End Date, ';
      }
      if (!modalFieldState.isLeaseExecutionDateValid) {
        fieldError += 'Lease Execution Date, ';
      }
      if (!modalFieldState.isLeaseOccupancyDateValid) {
        fieldError += 'Lease Occupancy Date,';
      }
      if (!modalFieldState.tenantPOCPhoneValid) {
        fieldError += 'Tenant POC Phone, ';
      }
      if (!modalFieldState.landlordPOCPhoneValid) {
        fieldError += 'Landlord POC Phone, ';
      }
      dispatch(toast(`Error in field validation: ${fieldError.trim().replace(/,\s*$/, '')}`));
    }
  };

  const handleImportDataAndContinue = () => {
    const updateSuccess = handleUpdateAll();
    if (updateSuccess) {
      setActiveTab('lease-comp');
    }
  };

  const handleImportDataAndClose = () => {
    const updateSuccess = handleUpdateAll();
    if (updateSuccess) {
      closeDataModal();
    }
  };

  return (
    <div className="data-modal">
      <div className="lease-abstraction-modal-header">
        <div className="header-text">Import Data to Voucher - [{dealName}]</div>
        <div className="header-buttons">
          <FaRegWindowMinimize
            onClick={hideDataModal}
            className="modal-button-minimize"
          />
          <IconButton
            className="modal-button-close"
            iconName="close"
            onClick={closeDataModal}
            aria-label="close"
            variant="basic"
          />
        </div>
      </div>
      <div className="lease-abstraction-modal-content">
        <div className="data-modal-fields">
          <div className="data-modal-field-content">
            <div className="instructions-container">
              <div className="instructions">
                <div className="instruction">
                  <span className="instruction-number">1.</span>
                  <p className="instruction-text">
                    Review the extracted data and overwrite fields as needed
                  </p>
                </div>
                {/* <div className="instruction">
                  <span className="instruction-number">2.</span>
                  <p className="instruction-text instruction-flex-display">
                    Select the{' '}
                    <span className="instruction-info-icon">
                      <Icon iconName="info-circle" variant="primary" />
                    </span>{' '}
                    to view the original text
                  </p>
                </div> */}
                <div className="instruction">
                  <span className="instruction-number">2.</span>
                  <p className="instruction-text">
                    To Import the extracted data to your Deal IQ voucher, select{' '}
                    <span className="instruction-italics">
                      Import Data & Continue
                    </span>{' '}
                    or just{' '}
                    <span className="instruction-italics">Import Data</span> to
                    stay on the current tab
                  </p>
                </div>
                {showMoreInstructions && (
                  <>
                    <div className="instruction">
                      <span className="instruction-number">3.</span>
                      <p className="instruction-text">
                        Repeat the import process on each tab
                      </p>
                    </div>
                    <br />
                    <div className="instruction">
                      <span className="instruction-number"> </span>
                      <p
                        className="instruction-text"
                        style={{ fontStyle: 'italic' }}
                      >
                        *&nbsp;&nbsp;indicates the page number was derived using
                        fuzzy logic and may be &nbsp;&nbsp;inaccurate at times
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div
                className="show-details-toggle"
                onClick={() => {
                  setShowMoreInstructions(!showMoreInstructions);
                }}
              >
                <p className="show-details-text">
                  {showMoreInstructions ? 'Show Less' : 'Show More'}
                </p>
              </div>
            </div>
            <div className="navigation-container">
              <ul className="navigation-list">
                <li
                  className={`navigation-list-item ${
                    activeTab === 'lease-details'
                      ? 'navigation-list-item--active'
                      : ''
                  }`}
                  onClick={() => {
                    setActiveTab('lease-details');
                  }}
                >
                  Lease Details
                </li>
                <li
                  className={`navigation-list-item ${
                    activeTab === 'lease-comp'
                      ? 'navigation-list-item--active'
                      : ''
                  }`}
                  onClick={() => {
                    setActiveTab('lease-comp');
                  }}
                >
                  Lease Comp
                </li>
              </ul>
              <div
                className={`navigation-content ${
                  showMoreInstructions
                    ? 'navigation-content-short'
                    : 'navigation-content-long'
                }`}
              >
                {activeTab === 'lease-details' && (
                  <LeaseDetails
                    modalFieldState={modalFieldState}
                    modalFieldChangeHandler={modalFieldChangeHandler}
                    propertyOptions={propertyOptions}
                    fieldErrorMap={fieldErrorMap}
                    validateLeaseAbstractionFields={validateLeaseAbstractionFields}
                  />
                )}
                {activeTab === 'lease-comp' && (
                  <>
                    <LeaseComp
                      modalFieldState={modalFieldState}
                      modalFieldChangeHandler={modalFieldChangeHandler}
                      fieldErrorMap={fieldErrorMap}
                      validateLeaseAbstractionFields={validateLeaseAbstractionFields}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="lease-abstraction-modal-footer">
            {/* <Button
              className="secondary-button"
              variant="secondary"
              onClick={() => {
                trackImportData();
                handleUpdateAll();
              }}
            >
              Import Data
            </Button> */}
            <Button
              className="primary-button"
              variant="primary"
              onClick={() => {
                trackImportData();
                if (activeTab === 'lease-details') {
                  handleImportDataAndContinue();
                } else {
                  handleImportDataAndClose();
                }
              }}
            >
              {activeTab === 'lease-details'
                ? 'Import Data And Continue'
                : 'Import Data And Close'}
            </Button>
          </div>
          <div className="disclaimer">
            <h2 style={{ color: '#3a7f3a', marginBottom: '5px' }}>
              Disclaimer
            </h2>
            The information contained herein include data that has been
            extracted using artificial intelligence technology. While the
            technology has been tested for accuracy and reliability, the data in
            each field have not been individually verified for accuracy or
            completeness. CBRE, Inc. makes no guarantee, representation or
            warranty and accepts no responsibility or liability as to the
            accuracy, completeness, or reliability of the extracted data
            contained herein. You should conduct a careful, independent
            investigation of all information. Any reliance on this information
            is solely at your own risk.
          </div>
        </div>
        <div className="data-modal-file">
          <div className="data-modal-file-content">
            <iframe
              id="pdf-js-viewer"
              src={pdfViewerUrl}
              title="webviewer"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

FileModal.propTypes = {
  hideDataModal: PropTypes.func,
  closeDataModal: PropTypes.func,
  results: PropTypes.array,
  deal: PropTypes.number,
  file: PropTypes.object,
  analyticProperties: PropTypes.object,
};

FileModal.defaultProps = {
  hideDataModal: () => {},
  closeDataModal: () => {},
  results: [],
  deal: null,
  file: {},
  analyticProperties: {},
};

export default FileModal;
