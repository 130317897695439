import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import CreateDealModalContainer from '../../redux/containers/create-deal-modal-container';
import Toast from '../../nucleus/toast/toast';
import SyncSalesForceModal from '../headers/deal-detail-header/sync-salesforce-modal';
import FileModal from '../file-upload/file-modal';
import { getAttachmentsExtract } from '../../services/attachments-service';
import { abstractionLeaseFile } from '../../redux/actions/deal-files-actions';

class AngularModalCenter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: undefined,
      trackingEventProperties: undefined,
      payload: undefined,
      promise: undefined,
      errorMessage: undefined,
      results: [],
      showDataModal: false,
    };
  }

  componentDidMount() {
    window.addEventListener('angularModalRequest', this.updateModalState);
  }


  componentDidCatch() {
    this.setState({
      modalType: undefined,
      trackingEventProperties: undefined,
      payload: undefined,
      promise: undefined,
      errorMessage: 'There was a problem duplicating your deal, please contact customer support.',
    });
    setTimeout(() => {
      this.setState({
        errorMessage: undefined,
      }, 3000);
    });
  }

  componentWillUnmount() {
    window.removeEventListener('angularModalRequest', this.updateModalState);
  }

  onSubmit = (value) => {
    const {
      promise,
    } = this.state;
    promise.resolve(value);
    setTimeout(() => {
      this.closeModal();
    }, 500);
  }

  onCancel = () => {
    const {
      promise,
    } = this.state;
    promise.reject();
    this.closeModal();
  }

  openDataModal = (dealId, fileId) => {
    console.log('open data moda called from angular', dealId, fileId);
    if (fileId) {
      getAttachmentsExtract({ deal: dealId, file: fileId }).then((res) => {
        this.setState({ results: res.result });
      });
    }
    this.setState({ showDataModal: true });
  }

  hideDataModal = () => {
    const { file } = this.state.payload;
    const { leaseFileUpdate } = this.props;
    this.setState({ showDataModal: false });
    localStorage.setItem(`leaseDeal_${file.deal}`, JSON.stringify(file));
    leaseFileUpdate(file);
  }

  closeDataModal = () => {
    const { file } = this.state.payload;
    const {leaseFileUpdate} = this.props;
    this.setState({ showDataModal: false });
    localStorage.removeItem(`leaseDeal_${file.deal}`);
    leaseFileUpdate({});
  }

  closeModal = () => {
    this.setState({
      modalType: undefined,
      trackingEventProperties: undefined,
      payload: undefined,
      promise: undefined,
      errorMessage: undefined,
    });
  }

  updateModalState = (req) => {
    const {
      modalType,
      trackingEventProperties,
      payload,
      promise,
    } = req;

    if (modalType) {
      this.setState({
        modalType,
        trackingEventProperties,
        payload,
        promise,
      }, () => {
        if (modalType === 'lease-abstraction') {
          const { file } = payload;
          this.openDataModal(file.deal, file.id);
        }
      });
    } else {
      throw new Error('A modal type is required');
    }
  }

  render() {
    const {
      modalType,
      trackingEventProperties,
      payload,
      errorMessage,
    } = this.state;
    let modal;
    const showModal = !!modalType;
    switch (modalType) {
      case 'followup-deal': {
        const {
          clientCompany,
        } = payload;
        modal = (<CreateDealModalContainer
          closeModal={this.closeModal}
          showModal={showModal}
          clientCompany={clientCompany}
          trackingEventProperties={trackingEventProperties}
        />);
        break;
      }
      case 'duplicate-deal': {
        const {
          deal,
          team,
        } = payload;
        modal = (<CreateDealModalContainer
          closeModal={this.closeModal}
          showModal={showModal}
          dealToDuplicate={deal}
          dealTeamToDuplicate={team}
          trackingEventProperties={trackingEventProperties}
        />);
        break;
      }
      case 'lease-abstraction': {
        const {
          file,
        } = payload;
        modal = this.state.showDataModal && (
          <>
            <div className="modal-backdrop" onClick={this.hideDataModal} />
            <FileModal
              hideDataModal={this.hideDataModal}
              closeDataModal={this.closeDataModal}
              results={this.state.results}
              file={file}
              analyticProperties={trackingEventProperties}
              deal={file.deal}
            />
          </>
        );
        break;
      }
      case 'sync-to-salesforce': {
        const {
          deal,
        } = payload;
        const {
          client,
          date_est_close: dateEstClose,
          deal_type: dealType,
          rep_role: repRole,
        } = deal || {};
        modal = (<SyncSalesForceModal
          showModal={showModal}
          dateEstClose={dateEstClose}
          isLeaseLandlord={repRole === 'landlord' && dealType === 'lease'}
          client={client}
          handleModalToggle={this.onCancel}
          handleModalSubmit={this.onSubmit}
        />);
        break;
      }
      default:
        return null;
    }
    const toast = errorMessage && <Toast message={errorMessage} />;
    return (
      <Fragment>
        {modal}
        {toast}
      </Fragment>
    );
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
      leaseFileUpdate: (file) => dispatch(abstractionLeaseFile(file))
  }
}

export default connect(null, mapDispatchToProps)(AngularModalCenter);
